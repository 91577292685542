var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("IntersectionObserver", { on: { intersect: _vm.onIntersect } }, [
    _c("div", { class: _vm.classes }, [
      _c(
        "div",
        {
          staticClass: "container",
          style: _vm.containerStyles,
          on: {
            dragenter: _vm.onDragEnter,
            dragleave: _vm.onDragLeave,
            dragover: _vm.onDragOver,
            drop: _vm.onDrop
          }
        },
        [
          _c(
            "div",
            { staticClass: "drop-zone__body" },
            [
              _vm._t("icon", null, { color: _vm.addIconFillColor }),
              _vm._v(" "),
              _c("span", { domProps: { textContent: _vm._s(_vm.title) } })
            ],
            2
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }