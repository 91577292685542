var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "expanding-list" },
    [
      _vm._t("header"),
      _vm._v(" "),
      _vm._t("body", function() {
        return [
          _c("DynamicScroller", {
            attrs: {
              items: _vm.expandedItems,
              "key-field": _vm.optionKey,
              prerender: _vm.prerender,
              "min-item-size": _vm.estimatedHeight
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var item = ref.item
                    var index = ref.index
                    var active = ref.active
                    return [
                      _c(
                        "DynamicScrollerItem",
                        { attrs: { item: item, active: active, index: index } },
                        [
                          _vm._t("item", null, {
                            index: index,
                            item: item,
                            onExpand: _vm.onExpandItem
                          })
                        ],
                        2
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ]
      }),
      _vm._v(" "),
      _vm._t("footer")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }